
import * as cloudflareRuntime$S4pr6fFOjF from '/opt/atlassian/pipelines/agent/build/nuxt-app/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as customProviderRuntime$jqiq7OyOBM from '/opt/atlassian/pipelines/agent/build/nuxt-app/providers/norce.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 767,
    "md": 1024,
    "lg": 1190,
    "xl": 1372,
    "xxl": 1600,
    "2xl": 1536
  },
  "presets": {
    "standard": {
      "modifiers": {
        "format": "webp",
        "quality": 60
      }
    },
    "productImage": {
      "modifiers": {
        "format": "webp",
        "quality": 90
      }
    },
    "blockImages": {
      "modifiers": {
        "format": "webp",
        "quality": 80
      }
    }
  },
  "provider": "cloudflare",
  "domains": [
    "localhost:8084",
    "swm-se.localhost:8081",
    "swm-no.localhost:8082",
    "swm-ch.localhost:8083",
    "intv2.swedishmatch.se",
    "intv2.swedishmatch.ch",
    "intv2.swedishmatch.no",
    "prepv2.swedishmatch.se",
    "prepv2.swedishmatch.ch",
    "prepv2.swedishmatch.no",
    "prodv2.swedishmatch.se",
    "prodv2.swedishmatch.ch",
    "prodv2.swedishmatch.no",
    "www.swedishmatch.se",
    "www.swedishmatch.ch",
    "www.swedishmatch.no",
    "swedishmatch.cdn.storm.io",
    "swedishmatch.cdn-stage.storm.io",
    "swematch22m7ss1inte.dxcloud.episerver.net"
  ],
  "alias": {
    "/intv2": "intv2.swedishmatch.se"
  },
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$S4pr6fFOjF, defaults: {} },
  ['norce']: { provider: customProviderRuntime$jqiq7OyOBM, defaults: {} }
}
        